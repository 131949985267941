body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "circular";
  src: url("https://cerclex-public-static-content.s3.ap-south-1.amazonaws.com/CircularStd-Book.otf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "circular";
  src: url("https://cerclex-public-static-content.s3.ap-south-1.amazonaws.com/CircularStd-Book.otf");
}

h3 {
  font-size: larger;
  font-weight: 500;
  font-family: "circular";
}

.cxcolor {
  background-color: #6F2DA8;
}

.cxcolortext {
  color: #6F2DA8;
}

.header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.hover{
  cursor: pointer;
}

/* .icon {
  width: 30px;
  height: 30px;
  margin: 0 10px;
} */

.icon-active {
  background-color: #6F2DA8;
}

.icon-active,
.icon-circle,
.icon-current {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #E0E0E0;
  margin: 0 10px;
}

.icon-current {
  border: 2px solid #6F2DA8;
}

.section {
  margin-top: 10rem;
  margin-bottom: 20px !important;
}

.container {
  display: flex;
  height: 100vh;
}

.material-symbols-outlined {
  /* color: #9747FF; */
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

/* .housing-type-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
} */

.house-label {
  position: relative;
  display: inline-block;
  border: 1px solid #C8C8C8;
  transition: border-color 0.3s;
  border-radius: 10px;
  padding: 40px;
  cursor: pointer;
}

.house-label-ismobile {
  position: relative;
  display: inline-block;
  border: 1px solid #C8C8C8;
  transition: border-color 0.3s;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.house-label-v2 {
  position: relative;
  display: inline-block;
  border: 1px solid #C8C8C8;
  transition: border-color 0.3s;
  border-radius: 10px;
  padding-inline: 40px;
  padding-bottom: 12.5px;
  cursor: pointer;
}

.house-label-v2 input[type="radio"] {
  position: absolute;
  top: 17px; /* Adjust as needed to position the radio button */
  left: 10px;
  opacity: 1; /* hide the default radio button */
  z-index: 2; /* make sure the radio button is above the image for click events */
}

.ismobile-overflow {
  overflow: scroll;
}

.house-label input[type="radio"] {
  position: absolute;
  top: 10px; /* Adjust as needed to position the radio button */
  left: 10px;
  opacity: 1; /* hide the default radio button */
  z-index: 2; /* make sure the radio button is above the image for click events */
}

.house-label-ismobile input[type="radio"] {
  position: absolute;
  top: 10px; /* Adjust as needed to position the radio button */
  left: 10px;
  opacity: 1; /* hide the default radio button */
  z-index: 2; /* make sure the radio button is above the image for click events */
}

.house-image {
  width: 8rem; 
  height: 5rem;
  display: block;
}

.house-image-ismobile {
  width: 8rem; 
  height: 5rem;
  display: block;
}

.radio-container input[type='radio'] {
  border-color: #FF3F40;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.lh-9 {
  line-height: 0.9px;
}

.counter-buttons {
  border: transparent;
  background-color: #E3c3ff;
  display: flex;
  align-items: center;
  width: 50px;
}

.counter-buttons-ismobile {
  border: transparent;
  background-color: #E3c3ff;
  display: flex;
  align-items: center;
  width: 50px;
}

.counter-buttons-container {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
}

.buttonsv1-container {
  border: 1px solid #D9D9D9;
  border-radius: 20px;
}

.buttonsv1-selected-container {
  border: 1px solid #6F2DA8;
  border-radius: 20px;
}

.buttonsv2-container {
  border: 1px solid;
  background-color: #6F2DA8;
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 500;
  padding-left: 1rem;
  padding-block: 0.3rem;
  font-family: 'circular';
  font-size: 18px;
}

.counter-font {
  flex-grow: 1;
  font-size: 18px;
}

.arrow-icon-next {
  font-size: 30px;
  margin-left: 5px;
}

.counter-font-ismobile {
  flex-grow: 1;
  font-size: 16px;
}

.input-containerr {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  width: 25vw;
  height: 5vh;
  padding-left: 10px;
}

.input-container-ismobile {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  height: 5vh;
  padding-left: 10px;
}

.input-border input[type=number]:focus {
  outline: none;
  border: none;
}

input[type=number] {
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  width: 25vw;
  height: 5vh;
}

input[type=number]:focus {
  outline: none;
  border: none;
}

.house-label input[type="radio"]:checked + .house-image {
  border-color: #6F2DA8; /* or any color you prefer for the selected state */
}
.house-label input[type="radio"]:checked ~ .house-label {
  border-color: #6F2DA8; /* Color for the selected state */
}

/* Hide the default checkbox */
.house-label input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox */
.house-label .custom-checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
/* .house-label:hover input[type="checkbox"] ~ .custom-checkbox {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a  background */
.house-label input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: #6F2DA8;
}

/* Create the checkmark/indicator (hidden when not checked) */
/* .house-label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
} */

/* Show the checkmark when checked */
.house-label input[type="checkbox"]:checked ~ .custom-checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.house-label .custom-checkbox:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.mandatory-field {
  color: #FF3F40;
  font-weight: bold;
}

.custom-dropdown {
  border: 1px solid #C8C8C8;
  border-radius: 8px;
  width: 25vw;
  height: 5vh;
  background-color: #FFFFFF;
}

.custom-dropdown-ismobile {
  border: 1px solid #C8C8C8;
  border-radius: 8px;
  width: 90vw;
  height: 5vh;
  background-color: #FFFFFF;
}

.custom-dropdown-option {
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: #FFFFFF;
  padding-left: 1rem;
  width: 25vw;
  padding-block: 0.01rem;
}

.custom-dropdown-option-ismobile {
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: #FFFFFF;
  padding-left: 1rem;
  width: 90vw;
  padding-block: 0.01rem;
}

/* When the checkbox is checked, add a  background */
.house-label-ismobile input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: #6F2DA8;
}

.house-label-ismobile .custom-checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
}

.top-right-corner {
  position: absolute;
  top: 5%;
  right: 5%; 
  cursor: pointer;
}

.btns {
  width: 10vw;

  @media (max-width: 480px) {
    width: 30vw; 
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    width: 14vw; 
  }
}

.close-container {
  background-color: #F3F3F3;
  border-radius: 4px;
  width: 25px;
  height: 20px;
  padding-bottom: 22px;
  padding-top: 2px;
}

/* In your component's CSS file */
/* Hide the scrollbar and scrollbar track */
::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width to your preference */
}

::-webkit-scrollbar-track {
  background: transparent; /* or any color you prefer */
}

/* Hide the scrollbar thumb (indicator) */
::-webkit-scrollbar-thumb {
  background: transparent; /* or any color you prefer */
}

.header {
  padding: 1rem;
}

.mt-2 {
  margin-top: 2rem;
  flex-shrink: 0;
}

.bold.hover {
  cursor: pointer;
  color: #6F2DA8;
  padding-left: 40px;
  font-size: 20px;
}

.flex.columns {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.icon-current img, .icon-active img, .icon-circle img {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.mt-2.center {
  margin-top: 2rem;
  text-align: center;
  font-size: 24px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .bold.hover {
      padding-left: 1px;
      font-size: 8px;
  }

  .icon-current, .icon-active, .icon-circle {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;  
}

.icon-current img, .icon-active img, .icon-circle img {
    width: 50%; 
    height: auto;
}

  .mt-2.center {
      font-size: 20px;
  }
}


